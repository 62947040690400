<template>
  <v-container fluid>
    <v-card elevation="5">
      <v-card-title
        >Inventory Management
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="getAllStock" :search="search">
        <template v-slot:item.total_stock="{ item }">
          {{ getTotalStock(item.variations) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-left align-items-center" :id="item.id">
            <div class="my-2">
              <v-btn color="error" small dark @click="viewStock(item)">
                <v-icon dark size="14" class="pr-2">mdi-eye</v-icon> View
                Product Stock
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" max-width="900px">
        <v-card elevation="5">
          <v-card-title
            >Product Details
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="simpleHeader"
            :items="currentStock"
            :search="search"
          >
            <template  v-slot:item.color="{ item }">
             <p v-if="item.colors"> {{ item.colors.name }} </p>
            </template>
            <template  v-slot:item.size="{ item }">
             <p v-if="item.size"> {{ item.size.name }}</p>
            </template>
            <template v-slot:item.stock="{ item }">
              <v-edit-dialog
                :return-value.sync="item.stocks"
                large
                persistent
                @save="save(item)"
                @open="open(item.id)"
                @close="close"
                class="editDialog"
              >
                <v-btn elevation="0" outlined
                  ><span style="color:black">{{ item.stocks }}</span></v-btn
                >
                <template v-slot:input>
                  <div class="mt-4 text-h6">
                    Update Stocks
                  </div>
                  <v-text-field
                    v-model="item.stocks"
                    label="Edit"
                    single-line
                    counter
                    autofocus
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      search: "",
      dialog: false,
      valid: true,
      editedProduct: null,
      editedVariation: null,
      editedStock: null,
      stockId: null,
      stock: null,
      // inventory: [],
      currentStock: null,
      editedIndex: -1,
      total_stock: 100,
      editedItem: {},
      defaultItem: {},
      headers: [
        { text: "S.N.", value: "sn" },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Total Quantity", value: "total_stock" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      simpleHeader: [
        { text: "Color", align: "left", value: "color" },
        {
          text: "Size",
          align: "left",
          value: "size",
        },
        { text: "Weight", align: "left", value: "weight" },
        {
          text: "Type",
          align: "left",
          value: "type",
        },
        { text: "Price", align: "left", value: "price" },
        {
          text: "Selling Price",
          align: "left",
          value: "selling_price",
        },
        {
          text: "Stock",
          align: "left",
          value: "stock",
        },
      ],
    };
  },
  computed: {
    // ...mapGetters({
    //   products: "getStocks",
    // }),
    ...mapState({
      products: (state) => state.products,
    }),

    getAllStock() {
      return this.products.map((item, index) => ({
        ...item,
        sn: index + 1,
        name: item.name,
        stock: item.variations,
        weight: item.weight ? item.weight : "-",
      }));
    },
  },
  mounted() {
    // if (!this.$store.state.stocks.length) {
    //   this.getStocks().then((response) => {
    //     this.inventory = response;
    //     console.log(response);
    //   });
    // } else {
    //   this.inventory = this.$store.state.stocks;
    // }
    // this.dialog = false;
  },
  methods: {
    addQuantity() {
      this.dialog = !this.dialog;
    },

    getTotalStock(variations) {
      return variations.reduce(function(tot, arr) {
        return parseInt(tot + arr.stocks);
      }, 0);
    },

    ...mapActions(["updateStock"]),

    viewStock(item) {
      this.editedProduct = item.id;
      this.currentStock = item.variations;
      this.dialog = true;
    },

    open(item) {
      this.stockId = item;
    },

    close() {
      console.log("Dialog closed");
    },

    save(item) {
      this.editedStock = item;
      let temp = this.products.filter(
        (stock) => stock.id === this.editedProduct.id
      )[0];
      this.editedIndex = this.products.indexOf(temp);
      let data = {
        stocks: item.stocks,
        id: item.id,
      };
      this.updateStock(data)
        .then(() => {
          this.$store.commit("setProducts", this.products);
        })
        .catch((error) => {
          // Object.assign(this.inventory[this.editedIndex], temp);
          console.error(error);
        });

      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
button.v-btn.v-btn--text.theme--light.v-size--default.primary--text {
  background-color: $primary-color !important;
  margin-left: 5px;
}
</style>
