var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v("Inventory Management "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getAllStock,"search":_vm.search},scopedSlots:_vm._u([{key:"item.total_stock",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalStock(item.variations))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left align-items-center",attrs:{"id":item.id}},[_c('div',{staticClass:"my-2"},[_c('v-btn',{attrs:{"color":"error","small":"","dark":""},on:{"click":function($event){return _vm.viewStock(item)}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"dark":"","size":"14"}},[_vm._v("mdi-eye")]),_vm._v(" View Product Stock ")],1)],1)])]}}])}),_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v("Product Details "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.simpleHeader,"items":_vm.currentStock,"search":_vm.search},scopedSlots:_vm._u([{key:"item.color",fn:function(ref){
var item = ref.item;
return [(item.colors)?_c('p',[_vm._v(" "+_vm._s(item.colors.name)+" ")]):_vm._e()]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [(item.size)?_c('p',[_vm._v(" "+_vm._s(item.size.name))]):_vm._e()]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{staticClass:"editDialog",attrs:{"return-value":item.stocks,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "stocks", $event)},"update:return-value":function($event){return _vm.$set(item, "stocks", $event)},"save":function($event){return _vm.save(item)},"open":function($event){return _vm.open(item.id)},"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" Update Stocks ")]),_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":"","autofocus":""},model:{value:(item.stocks),callback:function ($$v) {_vm.$set(item, "stocks", $$v)},expression:"item.stocks"}})]},proxy:true}],null,true)},[_c('v-btn',{attrs:{"elevation":"0","outlined":""}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(item.stocks))])])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }